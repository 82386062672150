<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="100"
    class="grid-card-estabelecimento"
  >
    <div
      class="card-estabelecimento"
      v-for="item in data"
      :key="item.id"
      @click="showModal(item)"
    >
      <div class="card-estabelecimento-main">
        <img
          src="../assets/icons/dicas/market3_green.svg"
          alt="Ícone de lojinha"
        />

        <div class="info">
          <p class="title-info">{{ item.nome }}</p>
          <p class="phone-info">
            <b-icon-phone /> {{ item.telefones[0].numero }}
          </p>
          <p>
            <b-icon-geo />
            {{
              (item.cidadeObj ? item.cidadeObj.nome : "") +
                " - " +
                (item.estado ? item.estado.uf : "")
            }}
          </p>
          <p>
            <b-icon-forward-fill />
            Bairro
            {{ item.enderecoObj ? item.enderecoObj.bairro : "" }}
          </p>
          <p class="address-info">
            <b-icon-geo-alt />
            {{
              item.enderecoObj
                ? item.enderecoObj.rua +
                  ", " +
                  item.enderecoObj.numero +
                  (item.enderecoObj.referencia
                    ? " - " + item.enderecoObj.referencia
                    : "")
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="card-estabelecimento-secondary">
        <p v-for="tipo in item.tiposEstabelecimento" :key="tipo.nome">
          {{ tipo.nome }}
        </p>
      </div>
    </div>

    <!-- modal de compartilhamento -->
    <b-modal centered hide-footer ref="modal-compartilhamento">
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">Compartilhar estabelecimento:</h5>
        <button
          type="button"
          aria-label="Close"
          class="close margin-left"
          @click="close"
        >
          ×
        </button>
      </template>

      <div v-for="(network, index) in networks" :key="index">
        <ShareNetwork
          :network="network.name"
          :url="
            network.name == 'whatsapp' ? '' : 'http://fiquenolar.ifce.edu.br/'
          "
          :title="network.title"
          :description="network.description"
          :quote="network.quote"
          :hashtags="network.hashtags"
        >
          <div class="icone-modal-compartilhamento">
            <b-link>
              <unicon :name="network.name" :fill="network.color" />
            </b-link>
            <h5 class="networkNome">{{ network.name }}</h5>
          </div>
        </ShareNetwork>
      </div>
    </b-modal>

    <!-- modal descrição do estabelecimento -->
    <b-modal ref="referencia" scrollable hide-footer>
      <!-- Cabeçalho -->
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title title-max-size">{{ obj.nome }}</h5>
        <div class="alinhar-close-button">
          <button
            type="button"
            aria-label="Close"
            class="close margin-left"
            @click="showModalShared"
          >
            <img
              class="share-img"
              alt="share"
              src="../assets/icons/share_icons/share_icon_4.svg"
              width="25px"
            />
          </button>
          <button
            type="button"
            aria-label="Close"
            class="close margin-left"
            @click="close"
          >
            ×
          </button>
        </div>
      </template>

      <p class="tag-item" v-if="obj.isDelivery">Delivery</p>
      <p class="tag-item" v-if="obj.isPermiteRetirada">Retirada</p>
      <p>
        <b-icon-geo /> <b>{{ $t("geral.labels.cidade") }}:</b>
        {{
          (obj.cidadeObj ? obj.cidadeObj.nome : "") +
            " - " +
            (obj.estado ? obj.estado.uf : "")
        }}
      </p>
      <p>
        <b-icon-forward-fill /> <b>{{ $t("geral.labels.bairro") }}:</b>
        {{ obj.enderecoObj ? obj.enderecoObj.bairro : "" }}
      </p>
      <p>
        <b-icon-geo-alt /> <b>{{ $t("geral.labels.endereco") }}:</b>
        {{
          obj.enderecoObj
            ? obj.enderecoObj.rua +
              ", " +
              obj.enderecoObj.numero +
              (obj.enderecoObj.referencia
                ? " - " + obj.enderecoObj.referencia
                : "")
            : ""
        }}
      </p>
      <p>
        <b-icon-calendar /> <b>{{ $t("geral.labels.dias-funcionamento") }}:</b>
        <span v-for="dia in obj.diasFuncionamento" :key="dia">
          <br />{{ getDay(dia) }}
        </span>
      </p>
      <p v-if="obj.horarioFuncionamento">
        <b-icon-clock /> <b>{{ $t("geral.labels.horario-funcionamento") }}:</b>
        {{ obj.horarioFuncionamento }}
      </p>
      <p v-if="obj.isDelivery">
        <b-icon-credit-card />
        <b
          >{{ $t("geral.labels.valor-entrega") }}:
          {{ $t("geral.labels.simbolo-moeda") }}</b
        >
        {{ obj.valorDelivery }}
      </p>
      <p v-if="obj.tempoEspera">
        <b-icon-clock-history /> <b>{{ $t("geral.labels.tempo-espera") }}:</b>
        {{ obj.tempoEspera }} {{ $t("geral.labels.minutos") }}
      </p>
      <p>
        <b-icon-grid />
        <b>{{ $t("geral.labels.categorias-estabelecimento") }}:</b> <br />
        <span v-for="tipo in obj.tiposEstabelecimento" :key="tipo.nome">
          <!-- <br /> -->
          <p class="tag-item">{{ tipo.nome }}</p>
        </span>
      </p>
      <!-- Descrição -->
      <p v-if="obj.descricao">
        <b-button v-b-toggle.collapse-2 class="m-1" variant="outline-secondary">
          {{ $t("geral.labels.mais-info") }}
          <b-icon-chevron-down font-scale="0.9" />
        </b-button>
        <b-collapse id="collapse-2">
          <b-card class="mt-2">
            {{ obj.descricao }}
          </b-card>
        </b-collapse>
      </p>

      <!-- Compartilhamento -->
      <!-- <b
        ><p>{{ $t("geral.labels.compartilhar-estabelecimento") }}</p></b
      >
      <b-row class="d-flex justify-content-around">
        <ShareNetwork
          v-for="(network, index) in networks"
          :key="index"
          :network="network.name"
          :url="
            network.name == 'whatsapp'
              ? 'Acesse http://fiquenolar.ifce.edu.br/'
              : 'http://fiquenolar.ifce.edu.br/'
          "
          :title="network.title"
          :description="network.description"
          :quote="network.quote"
          :hashtags="network.hashtags"
        >
          <b-link><unicon :name="network.name" :fill="network.color"/></b-link>
        </ShareNetwork>
      </b-row> -->
      <!-- <hr /> -->
      <b-link
        class="btn btn-block mb-3 btn-primary"
        :href="`https://facebook.com/${obj.facebook.replace(/@/gi, '')}`"
        v-if="obj.facebook"
        target="_blank"
      >
        <unicon name="facebook" fill="white" />
        {{ obj.facebook.replace(/@/gi, "") }}
      </b-link>

      <b-link
        class="btn btn-block mb-3 bg-instagram"
        :href="`https://instagram.com/${obj.instagram.replace(/@/gi, '')}`"
        v-if="obj.instagram"
        target="_blank"
      >
        <unicon name="instagram" fill="white" />
        {{ obj.instagram.replace(/@/gi, "") }}
      </b-link>
      <b-link
        class="btn btn-block mb-3"
        :class="{
          'btn-success': telefone.whatsapp,
          'btn-info': !telefone.whatsapp
        }"
        :href="
          telefone.whatsapp
            ? `https://api.whatsapp.com/send/?phone=55${telefone.numero.replace(
                /\(|\)\s|-/gi,
                ''
              )}&text=${encodeURIComponent(msg_api_whatsapp)}&app_absent=0`
            : `tel:${telefone.numero.replace(/\(|\)\s|-/gi, '')}`
        "
        v-for="(telefone, index) in obj.telefones"
        :key="index"
        v-show="telefone.numero"
        target="_blank"
      >
        <unicon :name="telefone.whatsapp ? 'whatsapp' : 'phone'" fill="white" />
        {{ telefone.numero }}
      </b-link>

      <b-link
        class="btn btn-block mb-3 btn btn-info"
        :href="`mailto:${obj.email}`"
        v-if="obj.email"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-envelope"
          viewBox="0 0 16 16"
        >
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
          />
        </svg>
        {{ obj.email }}
      </b-link>

      <b-link
        class="btn btn-block mb-3 btn-secondary"
        :href="`${obj.site}`"
        v-if="obj.site"
        target="_blank"
      >
        <unicon name="globe" fill="white" />
        {{ obj.site }}
      </b-link>

      <!--
      <b-link
        class="btn btn-block mb-3 btn-ifood"
        :href="`${obj.ifood}`"
        v-if="obj.ifood"
        target="_blank"
      >
        <img
          class="share-img"
          alt="share"
          src="../assets/icons/icons_site/ifood.png"
          width="35px"
        />
        {{ "Parceiro Ifood" }}
      </b-link>
      -->
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    updateData: {
      type: Function
    }
  },
  data() {
    return {
      msg_api_whatsapp:
        "Olá, tudo bem?, encontrei seu estabelecimento na plataforma #FiqueNoLar.com.",
      obj: {},
      networks: [
        {
          name: "facebook",
          title: "",
          description: "",
          quote: "",
          hashtags: "fiquenolar",
          color: "#4267b2"
        },
        {
          name: "twitter",
          title: "",
          description: "",
          quote: "",
          hashtags: "fiquenolar",
          color: "#1da1f2"
        },
        {
          name: "whatsapp",
          title: "",
          description: "",
          quote: "",
          hashtags: "fiquenolar",
          color: "#25d366"
        }
      ]
    };
  },
  methods: {
    loadMore() {
      this.updateData();
    },
    showModalShared() {
      this.$refs["modal-compartilhamento"].show();
    },
    showModal(obj) {
      this.obj = obj;
      this.descriptionNetworks(obj);
      this.$refs["referencia"].show();
    },
    getDay(name) {
      switch (name) {
        case "SEGUNDA_FEIRA":
          return "Segunda-feira";
        case "TERCA_FEIRA":
          return "Terça-feira";
        case "QUARTA_FEIRA":
          return "Quarta-feira";
        case "QUINTA_FEIRA":
          return "Quinta-feira";
        case "SEXTA_FEIRA":
          return "Sexta-feira";
        case "SABADO":
          return "Sábado";
        case "DOMINGO":
          return "Domingo";
        default:
          break;
      }
    },

    descriptionNetworks(obj) {
      const description = this.descriptionPublish(obj);
      this.networks[0].quote = description;
      this.networks[1].title = description;
      this.networks[2].title = description;
    },
    descriptionPublish(obj) {
      const tiposEstabelecimento = obj.tiposEstabelecimento.map(
        tipo => ` ${tipo.nome}`
      );

      let apresentation_text = `Olá, somos o/a ${obj.nome} e estamos na plataforma #FiqueNoLar.com. Fornecemos serviços de${tiposEstabelecimento}.\n`;
      apresentation_text += `Disponível: ${this.isDeliveryAndRetirada()}.\n\n`;

      const phone_description = this.descriptionPhone(obj.telefones);

      const social_networks_description = this.descriptionSiteAndSocialNetworks();

      return `${apresentation_text}${phone_description}${social_networks_description}`;
    },
    descriptionSiteAndSocialNetworks() {
      let description = "";

      if (this.obj.facebook !== "" && this.obj.facebook !== null) {
        if (this.obj.facebook?.includes("facebook.com"))
          description += `Facebook:\n${this.obj.facebook}\n`;
        else
          description += `Facebook:\nhttps://facebook.com/${this.obj.facebook}\n`;
      }

      if (this.obj.instagram !== "" && this.obj.instagram !== null) {
        let insta_user = this.obj.instagram;
        if (insta_user[0] === "@") insta_user = insta_user.replace("@", "");
        description += `Instagram:\nhttps://instagram.com/${insta_user}\n`;
      }

      if (this.obj.site !== "" && this.obj.site !== null)
        description += `Site:\n${this.obj.site}\n`;

      if (description === "") return "";
      return `\nSites e/ou Redes Sociais:\n${description}`;
    },
    isDeliveryAndRetirada() {
      if (this.obj.isDelivery && this.obj.isPermiteRetirada)
        return "Delivery e Retirada";
      if (this.obj.isDelivery) return "Delivery";
      return "Retirada";
    },
    descriptionPhone(telefones) {
      let phone_description =
        "Entre em contato conosco através de nosso(s) contato(s).\n";
      let whatsapp = "";
      let commercial_number = "";

      for (const telefone of telefones) {
        if (telefone.whatsapp) {
          whatsapp += `${telefone.numero}\n`;
        }
        commercial_number += `${telefone.numero}\n`;
      }

      if (whatsapp.length > 0) {
        phone_description += `Whatsapp(s):\n${whatsapp}\n`;
      }

      return (phone_description += `Telefone(s):\n${commercial_number}`);

      // let whatsapp = [];
      // for (let telefone of telefones) {
      //   if (telefone.whatsapp) whatsapp.push(telefone.numero);
      // }

      // if (whatsapp.length > 0)
      //   return `\nEntre em contato conosco através do whatsapp ${whatsapp} ou do(s)`;
      // return "\nEntre em contato conosco através do(s)";
    }
  }
};
</script>

<style scoped>
.alinhar-close-button {
  display: flex;
}

.margin-left {
  margin-left: 18px;
}

.title-max-size {
  /* max-width: 55%;    */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.icone-modal-compartilhamento {
  display: flex;
  margin-bottom: 10px;
}

.networkNome {
  margin-left: 10px;
}

.share-img {
  padding-right: 0px;
  margin-right: 0px;
}

/*
.btn-ifood {
  background-color: #ea1d2c;
  color: white;
}
*/
</style>
