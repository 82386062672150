<template>
  <section class="servicos-page">
    <div class="servicos-page-filter" :class="{ teste: $sidebar.showSidebar }">
      {{ $t("geral.labels.filtros") }}
      <hr class="hr" />
      <b-form-group
        :label="$t('geral.labels.estado')"
        label-size="sm"
        label-for="input-estado"
      >
        <v-select
          id="input-estado"
          v-model="estadoFilter"
          :options="estados"
          label="name"
          :placeholder="$t('geral.placeholders.servicos-estado')"
          @input="changedEstado()"
        ></v-select>
      </b-form-group>
      <b-form-group
        :label="$t('geral.labels.cidade')"
        label-size="sm"
        label-for="input-1"
      >
        <v-select
          id="input-1"
          v-model="cidadeFilter"
          :options="cidades"
          label="nome"
          :placeholder="$t('geral.placeholders.servicos-cidade')"
          :disabled="!cidades.length > 0"
          @input="getEmpresas"
        ></v-select>
      </b-form-group>
      <b-form-group
        :label="$t('geral.labels.bairro')"
        label-size="sm"
        label-for="input-bairro"
      >
        <b-form-input
          id="input-bairro"
          v-model="bairroFilter"
          :placeholder="$t('geral.placeholders.servicos-bairro')"
          v-debounce:500ms="getEmpresas"
        />
      </b-form-group>
      <b-form-group
        :label="$t('geral.labels.categoria-estabelecimento')"
        label-size="sm"
        label-for="input-2"
      >
        <v-select
          id="input-2"
          v-model="tiposEstabelecimentoFilter"
          :options="tiposEstabelecimento"
          :placeholder="$t('geral.placeholders.servicos-categoria')"
          label="nome"
          @input="getEmpresas"
        ></v-select>
      </b-form-group>
      <b-form-group
        :label="$t('geral.labels.dias-funcionamento')"
        label-size="sm"
        label-for="input-3"
      >
        <v-select
          id="input-3"
          v-model="diasFuncionamentoFilter"
          :options="dias"
          :placeholder="$t('geral.placeholders.servicos-funcionamento')"
          label="text"
          @input="getEmpresas"
        ></v-select>
      </b-form-group>
      <b-form-checkbox
        class="mb-4 mt-4"
        v-model="isDeliveryFilter"
        @input="getEmpresas"
      >
        {{ $t("servicos.delivery") }}
      </b-form-checkbox>
      <b-form-checkbox v-model="isPermiteRetiradaFilter" @input="getEmpresas">
        {{ $t("servicos.retirada") }}
      </b-form-checkbox>
      <hr />
      <b-button
        style="margin-bottom: 5rem;"
        block
        variant="success"
        @click="resetForm"
        >{{ $t("geral.buttons.limpar-filtros") }}
      </b-button>
    </div>
    <div class="servicos-page-content">
      <b-input-group>
        <b-form-input
          v-model.trim="search"
          :placeholder="$t('geral.placeholders.procurando')"
          v-debounce:500ms="getEmpresas"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            size="sm"
            text="Button"
            variant="light"
            @click="getEmpresas"
          >
            <b-icon-search />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <!-- <div class="row  justify-content-end">
        <div class="d-flex align-items-end flex-column">
          <div class="mr-2 mt-auto p-2">Resultados: {{ totalResultado }}</div>
        </div>
      </div> -->
      <div class="row  justify-content-start">
        <div class="d-flex align-items-start flex-column">
          <div class="ml-2 p-2">
            {{ $t("geral.labels.resultados") }}: {{ totalResultado }}
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>
      <div
        v-if="empresas.length === 0"
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 100%"
      >
        <h2>{{ $t("geral.mensagens.nao-ha-dados") }}</h2>
        <p style="text-align: center;">
          {{ $t("geral.mensagens.pesquise-outro-termo-ou-cadastre") }}
        </p>
      </div>
      <grid-estabelecimentos
        :updateData="loadMore"
        :data="empresas"
        v-if="!loading"
      />

      <div
        v-if="loadingScroll"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>
    </div>
  </section>
</template>

<script>
import GridEstabelecimentos from "@/components/GridCardEstabelecimento";
import Service from "../services/ApiServices";
export default {
  components: {
    GridEstabelecimentos
  },
  data() {
    return {
      page: 0,
      size: 12,
      estados: [],
      cidades: [],
      empresas: [],
      tiposEstabelecimento: [],
      search: "",
      isLast: false,
      loadingScroll: false,
      loading: true,
      cidadeFilter: "",
      estadoFilter: "",
      tiposEstabelecimentoFilter: "",
      diasFuncionamentoFilter: "",
      bairroFilter: "",
      isDeliveryFilter: "",
      isPermiteRetiradaFilter: "",
      totalResultado: 0,
      dias: [
        { value: "DOMINGO", text: "Domingo" },
        { value: "SEGUNDA_FEIRA", text: "Segunda-feira" },
        { value: "TERCA_FEIRA", text: "Terça-feira" },
        { value: "QUARTA_FEIRA", text: "Quarta-feira" },
        { value: "QUINTA_FEIRA", text: "Quinta-feira" },
        { value: "SEXTA_FEIRA", text: "Sexta-feira" },
        { value: "SABADO", text: "Sábado" }
      ]
    };
  },
  methods: {
    loadMore() {
      if (this.isLast == false) {
        this.page = this.page + 1;
        const request = new Service();
        this.loadingScroll = true;
        request
          .findByParams(this.queryFilter, "empresas/findByParams")
          .then(res => {
            this.isLast = res.last;
            this.empresas = this.empresas.concat(...res.content);
            this.loadingScroll = false;
            this.totalResultado = res.totalElements;
          });
      }
    },
    getEmpresas() {
      this.loading = true;
      this.page = 0;
      const request = new Service();
      request.create(
        { ...this.queryFilter, uuid: localStorage.user_id, origem: "web" },
        "historicosBusca"
      );
      request
        .findByParams(this.queryFilter, "empresas/findByParams")
        .then(res => {
          this.isLast = res.last;
          this.empresas = res.content;
          this.loading = false;
          this.totalResultado = res.totalElements;
        });
    },
    verifyExistsProperty(obj, property) {
      return Object.prototype.hasOwnProperty.call(obj, property);
    },
    IsEmpty(str) {
      return str === "";
    },
    resetForm() {
      this.cidadeFilter = "";
      this.tiposEstabelecimentoFilter = "";
      this.diasFuncionamentoFilter = "";
      this.isDeliveryFilter = "";
      this.isPermiteRetiradaFilter = "";
    },
    getEstados() {
      const request = new Service();
      request
        .getAll({ sort: "nome", size: 27 }, "estados")
        .then(res => (this.estados = res.content));
    },
    changedEstado() {
      this.cidades = [];
      if (this.estadoFilter == null) this.estadoFilter = "";
      this.cidadeFilter = "";
      this.getEmpresas();
      this.getCidades(this.estadoFilter);
    },
    getCidades(estado) {
      const request = new Service();
      if (estado && estado.name)
        request
          .getAll(
            { sort: "nome", nomeEstado: estado.name },
            "cidades/findByEstado"
          )
          .then(res => (this.cidades = res.content));
    }
  },
  computed: {
    tiposEstabelecimentoSearch() {
      return this.tiposEstabelecimentoFilter !== "" &&
        this.tiposEstabelecimentoFilter !== null
        ? this.tiposEstabelecimentoFilter.nome
        : "";
    },
    estadoSearch() {
      return this.estadoFilter !== "" &&
        this.estadoFilter !== null &&
        this.estadoFilter != undefined &&
        this.estadoFilter.name
        ? this.estadoFilter.name
        : "";
    },
    cidadeSearch() {
      return this.cidadeFilter !== "" &&
        this.cidadeFilter !== null &&
        this.cidadeFilter != undefined &&
        this.cidadeFilter.nome
        ? this.cidadeFilter.nome
        : "";
    },
    diasFuncionamentoSearch() {
      return this.diasFuncionamentoFilter !== "" &&
        this.diasFuncionamentoFilter !== null
        ? this.diasFuncionamentoFilter.value
        : "";
    },
    isDeliverySearch() {
      return this.isDeliveryFilter ? this.isDeliveryFilter : "";
    },
    isPermiteRetiradaSearch() {
      return this.isPermiteRetiradaFilter ? this.isPermiteRetiradaFilter : "";
    },
    queryFilter() {
      return {
        tipoEstabelecimento: this.tiposEstabelecimentoSearch,
        diasFuncionamento: this.diasFuncionamentoSearch,
        isDelivery: this.isDeliverySearch,
        isPermiteRetirada: this.isPermiteRetiradaSearch,
        cidade: this.cidadeSearch,
        estado: this.estadoSearch,
        search: this.search,
        bairro: this.bairroFilter,
        page: this.page,
        size: this.size,
        sort: "cidadeObj.nome"
      };
    }
  },
  mounted() {
    const request = new Service();
    this.search = this.verifyExistsProperty(this.$route.params, "search")
      ? this.$route.params.search
      : "";
    if (
      this.verifyExistsProperty(
        this.$route.params,
        "cidadeFilter" && this.$route.params.cidadeFilter != ""
      )
    ) {
      this.estadoFilter = this.$route.params.cidadeFilter.state
        ? this.$route.params.cidadeFilter.state
        : "";
    } else if (this.verifyExistsProperty(this.$route.params, "estadoFilter")) {
      this.estadoFilter = this.$route.params.estadoFilter
        ? this.$route.params.estadoFilter
        : "";
    }

    this.cidadeFilter = this.verifyExistsProperty(
      this.$route.params,
      "cidadeFilter"
    )
      ? this.$route.params.cidadeFilter
      : "";
    this.tiposEstabelecimentoFilter = this.verifyExistsProperty(
      this.$route.params,
      "tipoEstabelecimento"
    )
      ? this.$route.params.tipoEstabelecimento
      : "";
    request
      .getAll({ sort: "nome", size: 50 }, "tiposEstabelecimento")
      .then(res => {
        this.tiposEstabelecimento = res.content;
      });
    request
      .findByParams(this.queryFilter, "empresas/findByParams")
      .then(res => {
        this.empresas = res.content;
        this.loading = false;
        this.totalResultado = res.totalElements;
      });
    request.create(
      { ...this.queryFilter, uuid: localStorage.user_id, origem: "web" },
      "historicosBusca"
    );
    this.getEstados();
    this.getCidades(this.estadoFilter);
  },
  beforeDestroy() {
    this.$sidebar.displaySidebar(false);
  }
};
</script>
